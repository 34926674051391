import React from "react";
import image1 from "../assets/csaa.png";



const Csaa = () => {
  return (
    <div>


      <div className="div-img">
 
        <div className="image-board">
          <img src={image1} alt="" className="testimonial__img" />
          <h3 className="cert__title">SAA-C03

          </h3>
        </div>
      </div>
    </div>
  );
};

export default Csaa;
