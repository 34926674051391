import React from "react";

const Data = () => {
  return (
    <div className="home__data  ">
      <h className="home__title ">Chiemela Umeh</h>

      <h3 className="home__subtitle"> Software Engineer | Cloud</h3>
      <p className="home__description">
        {/* Hello! Welcome to my website. My name is Chiemela. The fascination with
        how computers work drove me from Mechanical Engineering to Computer
        Science at the University of Houston, (Go Coogs! 🐾). Outside of work, I enjoy playing
        chess, working out, and attending music concerts. */}
        {/* I’m Chiemela, a Software Engineer with a passion for building scalable, 
   efficient solutions. My expertise spans a variety of practices,
    including CI/CD, DevOps, Linux systems Administration, automation,
     and cloud infrastructure. I enjoy solving complex problems and collaborating 
     with teams to deliver high-quality, reliable software.  */}
        I’m Chiemela, a software engineer passionate about building scalable 
        and resilient cloud solutions. My expertise spans CI/CD, Linux systems administration,
         automation, and monitoring. I enjoy solving complex problems and collaborating 
         with teams to deliver high-quality, reliable software.
      </p>
      {/* <a href="#contact" className="button button--flex center-button">
        <p>Say Hello</p>
      </a> */}
    </div>
  );
};

export default Data;
